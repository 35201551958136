<template>
  <Page full>
    <super-companies-body />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import SuperCompaniesBody from '../bodies/SuperCompanies.vue'

export default {
  mixins: [PageMixin],
  name: 'SuperItems',
  components: {
    SuperCompaniesBody
  }
}
</script>
