<template>
  <grid
    type="company"
    title="Companies"
    :multiple="true"
    :showDefaultFilterPresets="true"
    :order="[['company_time_created', 'desc']]"
    :isMain="isMain"
    :visible="[
      'company_preview',
      'company_status',
      'company_is_in_trial',
      'company_count_users',
      'company_has_onboarded',
      'counterparty_status',
      'company_time_created'
    ]"
  />
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  mixins: [BodyMixin],
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
